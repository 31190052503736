body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  padding: 16px;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.header {
  text-align: center;
  margin-bottom: 24px;
}

.header h1 {
  font-size: 24px;
  color: #333;
  margin: 0;
  padding: 16px 0;
}

.grid {
  padding: 8px;
}

.moduleCard {
  padding: 24px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: transform 0.2s;
  cursor: pointer;
  min-height: 140px;
}

.moduleCard:active {
  transform: scale(0.98);
}

.moduleIcon {
  font-size: 32px;
  margin-bottom: 12px;
}

.moduleTitle {
  font-size: 16px;
  font-weight: 500;
} 